<template>
  <div id="update-schedule">
    <b-form
      ref="form_update_schedule"
      @submit.prevent="updateSchedule"
    >
      <b-row>
        <b-col
          cols="6"
        >
          <label>Clinic</label>
          <v-select
            v-model="clinic"
            :options="clinicOptions"
            class="w-100"
            :reduce="name => name.code"
            label="name"
            placeholder="select clinic"
            disabled
          />
        </b-col>
        <b-col
          cols="6"
        >
          <label>Doctor</label>
          <v-select
            v-model="doctor"
            :options="doctorOptions"
            class="w-100"
            :reduce="name => name.code"
            label="name"
            placeholder="select doctor"
            disabled
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>Schedule</label>
          <v-select
            v-model="scheduleSelected"
            :options="scheduleLists"
            class="w-100"
            :reduce="name => name.id"
            label="name"
            placeholder="select schedule"
            @input="showScheduleInfo"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="startDate">Start date</label>
          <b-form-datepicker
            id="startDate"
            v-model="startDate"
            :min="nextDay"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>
        <b-col>
          <label for="endDate">End date</label>
          <b-form-datepicker
            id="endDate"
            v-model="endDate"
            :min="nextDay"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="startTime">Start Time</label>
          <v-select
            v-model="startTime"
            :options="startTimeOptions"
            class="w-100"
            :reduce="name => name.code"
            label="label"
            placeholder="select start time"
          />
        </b-col>
        <b-col>
          <label for="endTime">End Time</label>
          <v-select
            v-model="endTime"
            :options="endTimeOptions"
            class="w-100"
            :reduce="name => name.code"
            label="label"
            placeholder="select end time"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="amountOfPatient">Amount of Patient</label>
          <b-form-input
            id="amountOfPatient"
            v-model="amountOfPatient"
            type="Number"
          />
        </b-col>
      </b-row>
      <b-card
        class="mt-1"
        no-body
      >
        <b-card-body>
          <h6>Clinic: {{ clinic }} Doctor: {{ doctor }}</h6>
          <h6>Start: {{ startDate }}</h6>
          <h6>End: {{ endDate }}</h6>
          <h6>{{ startTime }} - {{ endTime }}</h6>
          <h6>Amount of Patient: {{ amountOfPatient }}</h6>
        </b-card-body>
      </b-card>
      <hr>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            class="mr-1"
            size="sm"
            @click="exitForm"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            size="sm"
          >
            Update
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    clinic: {
      type: String,
      default: () => '',
    },
    doctor: {
      type: String,
      default: () => '',
    },
    clinicOptions: {
      type: Array,
      default: () => [],
    },
    doctorOptions: {
      type: Array,
      default: () => [],
    },
    doctorCodeId: {
      type: Number,
      default: () => 0,
    },
    startTimeOptions: {
      type: Array,
      default: () => [],
    },
    endTimeOptions: {
      type: Array,
      default: () => [],
    },
    baseSchedule: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      curdate: new Date(),
      nextDay: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      amountOfPatient: 0,
      daySelected: '',
      scheduleSelected: '',
      scheduleLists: [],
      scheduleAllLists: [],
    }
  },
  created() {
    this.designSchedule()
  },
  methods: {
    designSchedule() {
      const tempSchedule = []
      for (let i = 0; i < this.baseSchedule.length; i += 1) {
        const scheduleDetails = this.baseSchedule[i]
        for (let s = 0; s < scheduleDetails.data.length; s += 1) {
          this.scheduleAllLists.push(scheduleDetails.data[s])
          tempSchedule.push(
            {
              id: scheduleDetails.data[s].scheduleID,
              name: `${scheduleDetails.data[s].day} ${scheduleDetails.data[s].startTime.substr(0, 5)} - ${scheduleDetails.data[s].endTime.substr(0, 5)}`,
              numOfPatient: scheduleDetails.data[s].numOfPatient,
              useDate: scheduleDetails.data[s].startUse.substr(0, 10),
            },
          )
        }
      }
      this.scheduleLists = tempSchedule
    },
    exitForm() {
      this.$emit('exit', 'formDeleteScheduleModal')
    },
    showScheduleInfo() {
      // console.log(this.scheduleSelected)
      for (let i = 0; i < this.scheduleAllLists.length; i += 1) {
        if (this.scheduleSelected === this.scheduleAllLists[i].scheduleID) {
          this.daySelected = this.scheduleAllLists[i].day
          this.startDate = this.scheduleAllLists[i].startUse.substr(0, 10)
          this.endDate = this.scheduleAllLists[i].endUse.substr(0, 10)
          this.startTime = this.scheduleAllLists[i].startTime.substr(0, 5)
          this.endTime = this.scheduleAllLists[i].endTime.substr(0, 5)
          this.amountOfPatient = this.scheduleAllLists[i].numOfPatient
        }
      }
    },
    updateSchedule(e) {
      e.preventDefault()
      this.$swal({
        title: 'Update schedule?',
        text: 'Are you sure you want to update this request?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // call Api To delete
          this.$http({
            url: '/api/DoctorSchedule/BaseSchedule',
            method: 'POST',
            data: {
              ScheduleID: this.scheduleSelected,
              DoctorCodeID: this.doctorCodeId,
              Day: this.daySelected,
              StartTime: `${this.startTime}:00`,
              EndTime: `${this.endTime}:00`,
              NumOfPatient: this.$helpers.IntNull(this.amountOfPatient),
              StartUse: this.startDate,
              EndUse: this.endDate,
              IsActive: true,
            },
          }).then(({ data }) => {
            console.log(data)
            this.$swal({
              title: 'Schedule Updated.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result2 => {
              if (result2.value) {
                this.$emit('exit', 'formUpdateScheduleModal')
              }
            })
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
