<template>
  <div id="appointment">
    <b-card no-body>
      <b-card-body>
        <!-- <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="mr-1"
              @click="showCreateAppointmentForm"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">New Appoinement</span>
            </b-button>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col
            md="6"
            sm="12"
            class="my-1"
          />
          <b-col
            md="6"
            class="my-1"
          >
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="patientLists"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <!-- @row-clicked="showUpdateAppointmentForm" -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item>
                    <feather-icon icon="InfoIcon" />
                    <span class="align-middle ml-50">Detail Appointment</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="showUpdateAppointmentForm(data)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit Appointment</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="deleteAppointment(data)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Cancel Appointment</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>

          <b-col
            cols="6"
          >
            <b-form-group
              class="mb-0"
            >
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Modal Update Appointment -->
    <b-modal
      id="formUpdateAppointmentModal"
      hide-footer
      centered
      size="lg"
      title="Update Appointment"
    >
      <form-update-appointment
        :hn="hn"
        :name="name"
        :clinic="clinic"
        :doctor="doctor"
        :clinic-options="clinicOptions"
        :doctor-options="doctorOptions"
        :doctor-code-id="doctorCodeId"
        :date-appointment="dateAppointment"
        :appointment-id="appointmentID"
        :patient-id="patientID"
        @exit="onCloseModal"
      />
    </b-modal>
    <!-- Modal Appointment Lists-->
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import FormUpdateAppointment from './FormUpdateAppointment.vue'

export default {
  components: {
    FormUpdateAppointment,
  },
  directives: {
    Ripple,
  },
  props: {
    clinic: {
      type: String,
      default: () => '',
    },
    doctor: {
      type: String,
      default: () => '',
    },
    clinicOptions: {
      type: Array,
      default: () => [],
    },
    doctorOptions: {
      type: Array,
      default: () => [],
    },
    doctorCodeId: {
      type: Number,
      default: () => 0,
    },
    dateAppointment: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      scheduleId: 0,
      hn: '',
      name: '',
      // clinic: '',
      // doctor: '',
      patientSelected: '',
      patientLists: [],
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'id', label: 'Id', visible: true },
        { key: 'date', label: 'Date', visible: true },
        { key: 'hn', label: 'HN', visible: true },
        { key: 'name', label: 'Name', visible: true },
        { key: 'by', label: 'By', visible: true },
        { key: 'updateDate', label: 'Update Date', visible: true },
        { key: 'appointmentID', label: 'appointmentID', visible: false },
        { key: 'patientID', label: 'patientID', visible: false },
        { key: 'scheduleID', label: 'scheduleID', visible: false },
        { key: 'appointmentNote', label: 'appointmentNote', visible: false },
        { key: 'comeForCode', label: 'comeForCode', visible: false },
        { key: 'actions', visible: true },
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      dateFilter: '',
      appointmentID: 0,
      patientID: 0,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    visibleFields() {
      return this.fields.filter(field => field.visible)
    },
  },
  mounted() { },
  created() {
    this.settingParameter()
    this.getFollowUp()
  },
  watch: { },
  methods: {
    settingParameter() {
      this.dateFilter = this.dateAppointment.replace('-', '').replace('-', '')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showCreateAppointmentForm() {
      this.$bvModal.show('formCreateAppointmentModal')
    },
    showUpdateAppointmentForm(arg) {
      // console.log(arg.item.appointmentID, arg.item.patientID)
      // this.$emit('setupParameter', arg.item.appointmentID, arg.item.patientID)
      this.appointmentID = arg.item.appointmentID
      this.patientID = arg.item.patientID
      this.hn = arg.item.hn
      this.name = arg.item.name
      this.$bvModal.show('formUpdateAppointmentModal')
    },
    getFollowUp() {
      this.scheduleId = 0
      this.patientLists = []
      this.$http({
        url: '/api/DoctorSchedule/DailyDoctorSchedule',
        method: 'POST',
        data: {
          DoctorCodeID: this.doctorCodeId,
          Day: '',
          DateFilter: this.dateAppointment,
        },
      }).then(({ data }) => {
        // this.patientLists = data
        const dataSchedule = data
        for (let s = 0; s < dataSchedule.scheduleDetails.length; s += 1) {
          if (dataSchedule.scheduleDetails[s].minPerPatient > 0) {
            // // console.log(dataSchedule.scheduleDetails[s].scheduleDetail)
            this.getFollowUpDetail(dataSchedule.scheduleDetails[s].scheduleDetail.scheduleID)
          }
        }
      })
    },
    getFollowUpDetail(scheduleID) {
      this.$http({
        url: '/api/Appointment/AppointmentDetail',
        method: 'POST',
        data: {
          DateFilter: this.dateFilter,
          PatientID: 0,
          ScheduleID: scheduleID,
        },
      }).then(({ data }) => {
        // this.patientLists = data
        // console.log(data)
        for (let i = 0; i < data.length; i += 1) {
          this.patientLists.push(
            {
              id: i + 1,
              date: data[i].appointment.appointmentDate.substr(0, 10),
              time: data[i].appointment.rangeAppointment,
              hn: data[i].patient.hn,
              name: `${data[i].patient.fName} ${data[i].patient.lName}`,
              by: data[i].appointment.updateBy,
              updateDate: data[i].appointment.updateDate.substr(0, 10),
              appointmentID: data[i].appointment.appointmentID,
              patientID: data[i].appointment.patientID,
              scheduleID: data[i].appointment.scheduleID,
              appointmentNote: data[i].appointment.appointmentNote,
              comeForCode: data[i].appointment.comeForCode,
              actions: null,
            },
          )
        }
      })
    },
    onCloseModal(id) {
      this.$bvModal.hide(id)
      this.getFollowUp()
    },
    deleteAppointment(arg) {
      const appointmentId = arg.item.appointmentID
      const patientId = arg.item.patientID
      const scheduleSelected = arg.item.scheduleID
      const dateNewAppointment = arg.item.date
      const note = arg.item.appointmentNote
      const comeFor = arg.item.comeForCode
      // // console.log(arg.item.appointmentNote, appointmentNote)
      // console.log(appointmentId, patientId, scheduleSelected, dateNewAppointment, comeFor, note)
      this.$swal({
        title: 'Delete Appointment?',
        text: 'Are you sure you want to delete this request?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http({
            url: '/api/Appointment/Appointment',
            method: 'POST',
            data: {
              appointment: {
                AppointmentID: appointmentId,
                ScheduleID: scheduleSelected,
                PatientID: patientId,
                AppointmentDate: dateNewAppointment,
                AppointmentNote: note,
                ComeForCode: comeFor,
                AppointmentStatus: 4,
                isActive: false,
              },
            },
          }).then(() => {
            // console.log(data)
            this.$swal({
              title: 'Appointment Canceled.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(res => {
              if (res.value) {
                this.getFollowUp()
              }
            })
          })
        }
      })
    },
  },
}
</script>
