<template>
  <div id="delete-schedule">
    <b-form ref="form_delete_schedule">
      <b-row>
        <b-col
          cols="6"
        >
          <label>Clinic</label>
          <v-select
            v-model="clinic"
            :options="clinicOptions"
            class="w-100"
            :reduce="name => name.code"
            label="name"
            placeholder="select clinic"
            disabled
          />
        </b-col>
        <b-col
          cols="6"
        >
          <label>Doctor</label>
          <v-select
            v-model="doctor"
            :options="doctorOptions"
            class="w-100"
            :reduce="name => name.code"
            label="name"
            placeholder="select doctor"
            disabled
          />
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-body>
          <b-col cols="12">
            <b-table
              striped
              hover
              responsive
              :items="scheduleLists"
            >
              <template #cell(id)="data">
                <b-form-checkbox
                  @change="addDeleteScheduleToList(data)"
                />
              </template>
            </b-table>
          </b-col>
        </b-card-body>
      </b-card>
      <hr>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            class="mr-1"
            size="sm"
            @click="resetForm"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            size="sm"
            @click="deleteSchedule"
          >
            Delete
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    clinic: {
      type: String,
      default: () => '',
    },
    doctor: {
      type: String,
      default: () => '',
    },
    clinicOptions: {
      type: Array,
      default: () => [],
    },
    doctorOptions: {
      type: Array,
      default: () => [],
    },
    baseSchedule: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      curdate: new Date(),
      scheduleFields: [
        { key: 'id', label: '' },
        { key: 'day', label: 'Day' },
        { key: 'startTime', label: 'Start Time' },
        { key: 'endTime', label: 'End Time' },
        { key: 'period', label: 'Period' },
        { key: 'useDate', label: 'Use Date' },
        { key: 'responsible', label: 'Responsible' },
        { key: 'location', label: 'Location' },
      ],
      scheduleLists: [],
      scheduleAllLists: [],
      deleteId: [],
      // :fields="scheduleFields"
    }
  },
  created() {
    this.designSchedule()
  },
  methods: {
    designSchedule() {
      const tempSchedule = []
      for (let i = 0; i < this.baseSchedule.length; i += 1) {
        const scheduleDetails = this.baseSchedule[i]
        for (let s = 0; s < scheduleDetails.data.length; s += 1) {
          this.scheduleAllLists.push(scheduleDetails.data[s])
          tempSchedule.push(
            {
              id: scheduleDetails.data[s].scheduleID,
              day: scheduleDetails.data[s].day,
              time: `${scheduleDetails.data[s].startTime.substr(0, 5)} - ${scheduleDetails.data[s].endTime.substr(0, 5)}`,
              numOfPatient: scheduleDetails.data[s].numOfPatient,
              useDate: scheduleDetails.data[s].startUse.substr(0, 10),
            },
          )
        }
      }
      this.scheduleLists = tempSchedule
    },
    resetForm() {
      this.$emit('exit', 'formDeleteScheduleModal')
    },
    addDeleteScheduleToList(index) {
      this.deleteId.push(index.item.id)
    },
    deleteSchedule(e) {
      e.preventDefault()
      this.$swal({
        title: 'Delete schedule?',
        text: 'Are you sure you want to delete this request?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // call Api To delete
          // console.log(this.scheduleAllLists, this.deleteId)
          for (let d = 0; d < this.deleteId.length; d += 1) {
            for (let i = 0; i < this.scheduleAllLists.length; i += 1) {
              // // console.log(this.deleteId[d], this.scheduleAllLists[i].scheduleID, this.deleteId[d] === this.scheduleAllLists[i].scheduleID)
              if (this.deleteId[d] === this.scheduleAllLists[i].scheduleID) {
                this.$http({
                  url: '/api/DoctorSchedule/BaseSchedule',
                  method: 'POST',
                  data: {
                    ScheduleID: this.scheduleAllLists[i].scheduleID,
                    DoctorCodeID: this.scheduleAllLists[i].doctorCodeID,
                    Day: this.scheduleAllLists[i].day,
                    StartTime: this.scheduleAllLists[i].startTime,
                    EndTime: this.scheduleAllLists[i].endTime,
                    NumOfPatient: this.scheduleAllLists[i].numOfPatient,
                    StartUse: this.scheduleAllLists[i].startUse.substr(0, 10),
                    EndUse: this.scheduleAllLists[i].endUse.substr(0, 10),
                    IsActive: false,
                  },
                })
                break
              }
            }
          }
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(result2 => {
            if (result2.value) {
              // this.$emit('exit', 'formDeleteScheduleModal')
              // // console.log(this.scheduleLists)
            }
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
