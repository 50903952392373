<template>
  <div id="create-schedule">
    <b-form
      ref="form_create_schedule"
      @submit.prevent="createSchedule"
    >
      <b-row>
        <b-col
          cols="6"
        >
          <label>Clinic</label>
          <v-select
            v-model="clinic"
            :options="clinicOptions"
            class="w-100"
            :reduce="name => name.code"
            label="name"
            placeholder="select clinic"
            disabled
          />
        </b-col>
        <b-col
          cols="6"
        >
          <label>Doctor</label>
          <v-select
            v-model="doctor"
            :options="doctorOptions"
            class="w-100"
            :reduce="name => name.code"
            label="name"
            placeholder="select doctor"
            disabled
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="startTime">Day</label>
          <b-form-group>
            <b-form-checkbox
              v-for="option in options"
              :key="option.value"
              v-model="daySelected"
              :value="option.value"
              name="flavour-4a"
              inline
            >
              {{ option.text }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="startDate">Start date</label>
          <b-form-datepicker
            id="startDate"
            v-model="startDate"
            :min="nextDay"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>
        <b-col>
          <label for="endDate">End date</label>
          <b-form-datepicker
            id="endDate"
            v-model="endDate"
            :min="nextDay"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="startTime">Start Time</label>
          <v-select
            v-model="startTime"
            :options="startTimeOptions"
            class="w-100"
            :reduce="name => name.code"
            label="label"
            placeholder="select start time"
          />
        </b-col>
        <b-col>
          <label for="endTime">End Time</label>
          <v-select
            v-model="endTime"
            :options="endTimeOptions"
            class="w-100"
            :reduce="name => name.code"
            label="label"
            placeholder="select end time"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="amountOfPatient">Amount of Patient</label>
          <b-form-input
            id="amountOfPatient"
            v-model="amountOfPatient"
          />
        </b-col>
      </b-row>
      <b-card
        class="mt-1"
        no-body
      >
        <b-card-body>
          <h6>Clinic: {{ clinic }} Doctor: {{ doctor }}</h6>
          <h6>Start: {{ startDate }}</h6>
          <h6>End: {{ endDate }}</h6>
          <h6>{{ startTime }} - {{ endTime }}</h6>
          <h6>Amount of Patient: {{ amountOfPatient }}</h6>
        </b-card-body>
      </b-card>
      <hr>
      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            class="mr-1"
            size="sm"
            @click="exitForm"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            size="sm"
          >
            Create
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    clinic: {
      type: String,
      default: () => '',
    },
    doctor: {
      type: String,
      default: () => '',
    },
    clinicOptions: {
      type: Array,
      default: () => [],
    },
    doctorOptions: {
      type: Array,
      default: () => [],
    },
    doctorCodeId: {
      type: Number,
      default: () => 0,
    },
    startTimeOptions: {
      type: Array,
      default: () => [],
    },
    endTimeOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      curdate: new Date(),
      nextDay: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: '',
      amountOfPatient: 0,
      daySelected: [],
      options: [
        { text: 'Sunday', value: 'Sunday' },
        { text: 'Monday', value: 'Monday' },
        { text: 'Tuesday', value: 'Tuesday' },
        { text: 'Wednesday', value: 'Wednesday' },
        { text: 'Thursday', value: 'Thursday' },
        { text: 'Friday', value: 'Friday' },
        { text: 'Saturday', value: 'Saturday' },
      ],
    }
  },
  methods: {
    exitForm() {
      this.$emit('exit', 'formCreateScheduleModal')
    },
    createSchedule(e) {
      e.preventDefault()
      // // console.log('Create Schedule')
      if (this.daySelected.length === 0) {
        return
      }
      if (this.amountOfPatient <= 0) {
        return
      }
      for (let i = 0; i < this.daySelected.length; i += 1) {
        this.$http({
          url: '/api/DoctorSchedule/NewBaseSchedule',
          method: 'POST',
          data: {
            DoctorCodeID: this.doctorCodeId,
            Day: this.daySelected[i],
            StartTime: `${this.startTime}:00`,
            EndTime: `${this.endTime}:00`,
            NumOfPatient: this.$helpers.IntNull(this.amountOfPatient),
            StartUse: this.startDate,
            EndUse: this.endDate,
          },
        }).then(({ data }) => {
          console.log(data)
          this.$swal({
            title: 'Schedule Created.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.$emit('exit', 'formCreateScheduleModal')
            }
          })
        })
      }
    },
  },
}
</script>

<style>

</style>
