<template>
  <div id="schedule">
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>Clinic</label>
            <v-select
              v-model="clinic"
              :options="clinicOptions"
              class="w-100"
              :reduce="name => name.code"
              label="name"
              placeholder="select clinic"
              @input="changeRoute()"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-md-0 mb-2"
          >
            <label>Doctor</label>
            <v-select
              v-model="doctor"
              :options="doctorOptions"
              class="w-100"
              :disabled="clinic === ''"
              :reduce="name => name.code"
              label="name"
              placeholder="select doctor"
              @input="getScheduleByDoctor"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col class="text-left">
            <b-button
              v-if="isScheduleMenuActive"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-info"
              size="sm"
              class="mr-1"
              @click="isScheduleHeaderActive=!isScheduleHeaderActive"
            >
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              <span class="align-middle">Schedule Details</span>
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-if="isScheduleMenuActive"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              size="sm"
              class="mr-1"
              @click="showCreateScheduleForm"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">Create</span>
            </b-button>
            <b-button
              v-if="isScheduleMenuActive"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-info"
              size="sm"
              class="mr-1"
              @click="showUpdateScheduleForm"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span class="align-middle">Update</span>
            </b-button>
            <b-button
              v-if="isScheduleMenuActive"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              size="sm"
              @click="showDeleteScheduleForm"
            >
              <feather-icon
                icon="XCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">Close</span>
            </b-button>
          </b-col>
        </b-row>
        <!-- <schedule-header
          v-if="isScheduleHeaderActive"
          :base-schedule="baseSchedule"
        /> -->
        <template>
          <div
            v-if="isScheduleHeaderActive"
            id="schedule-header"
          >
            <b-card class="border mb-0 mt-1">
              <b-card-text class="card-text">
                <b-row class="text-center">
                  <b-col>
                    <h6>Sun</h6>
                    <div
                      v-for="schedule, index of scheduleSun"
                      :key="index"
                    >
                      <h6 v-b-tooltip.hover.top="schedule.useRange">
                        {{ schedule.startTime.substr(0, 5) }}-{{ schedule.endTime.substr(0, 5) }} {{ schedule.numOfPatient }}
                      </h6>
                    </div>
                  </b-col>
                  <b-col>
                    <h6>Mon</h6>
                    <div
                      v-for="schedule, index of scheduleMon"
                      :key="index"
                    >
                      <h6 v-b-tooltip.hover.top="schedule.useRange">
                        {{ schedule.startTime.substr(0, 5) }}-{{ schedule.endTime.substr(0, 5) }} {{ schedule.numOfPatient }}
                      </h6>
                    </div>
                  </b-col>
                  <b-col>
                    <h6>Tue</h6>
                    <div
                      v-for="schedule, index of scheduleTue"
                      :key="index"
                    >
                      <h6 v-b-tooltip.hover.top="schedule.useRange">
                        {{ schedule.startTime.substr(0, 5) }}-{{ schedule.endTime.substr(0, 5) }} {{ schedule.numOfPatient }}
                      </h6>
                    </div>
                  </b-col>
                  <b-col>
                    <h6>Wed</h6>
                    <div
                      v-for="schedule, index of scheduleWed"
                      :key="index"
                    >
                      <h6 v-b-tooltip.hover.top="schedule.useRange">
                        {{ schedule.startTime.substr(0, 5) }}-{{ schedule.endTime.substr(0, 5) }} {{ schedule.numOfPatient }}
                      </h6>
                    </div>
                  </b-col>
                  <b-col>
                    <h6>Thu</h6>
                    <div
                      v-for="schedule, index of scheduleThu"
                      :key="index"
                    >
                      <h6 v-b-tooltip.hover.top="schedule.useRange">
                        {{ schedule.startTime.substr(0, 5) }}-{{ schedule.endTime.substr(0, 5) }} {{ schedule.numOfPatient }}
                      </h6>
                    </div>
                  </b-col>
                  <b-col>
                    <h6>Fri</h6>
                    <div
                      v-for="schedule, index of scheduleFri"
                      :key="index"
                    >
                      <h6 v-b-tooltip.hover.top="schedule.useRange">
                        {{ schedule.startTime.substr(0, 5) }}-{{ schedule.endTime.substr(0, 5) }} {{ schedule.numOfPatient }}
                      </h6>
                    </div>
                  </b-col>
                  <b-col>
                    <h6>Sat</h6>
                    <div
                      v-for="schedule, index of scheduleSat"
                      :key="index"
                    >
                      <h6 v-b-tooltip.hover.top="schedule.useRange">
                        {{ schedule.startTime.substr(0, 5) }}-{{ schedule.endTime.substr(0, 5) }} {{ schedule.numOfPatient }}
                      </h6>
                    </div>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
          </div>
        </template>
        <div class="mt-1 app-calendar overflow-hidden border">
          <div class="row no-gutters">
            <!-- Calendar -->
            <div class="col position-relative">
              <div class="card shadow-none border-0 mb-0 rounded-0">
                <div class="card-body pb-0">
                  <b-row
                    v-if="isScheduleMenuActive"
                    style="margin-bottom: -2rem !important;"
                  >
                    <b-col class="text-left">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        size="sm"
                        class="mr-1"
                        @click="prev"
                      >
                        <feather-icon
                          icon="ChevronLeftIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Prev</span>
                      </b-button>
                    </b-col>
                    <b-col class="text-right">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        size="sm"
                        class="mr-1"
                        @click="next"
                      >
                        <span class="align-middle">Next</span>
                        <feather-icon
                          icon="ChevronRightIcon"
                          class="ml-50"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                  <full-calendar
                    ref="scheduleCalendar"
                    :options="calendarOptions"
                    class="full-calendar"
                  />
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <!-- Modal Create Schedule -->
    <b-modal
      id="formCreateScheduleModal"
      hide-footer
      centered
      size="lg"
      title="Create Schesule"
    >
      <form-create-schedule
        :clinic="clinic"
        :doctor="doctor"
        :clinic-options="clinicOptions"
        :doctor-options="doctorOptions"
        :doctor-code-id="doctorCodeID"
        :start-time-options="startTimeOptions"
        :end-time-options="endTimeOptions"
        @exit="onCloseModal"
      />
    </b-modal>
    <!-- Modal Update Schedule -->
    <b-modal
      id="formUpdateScheduleModal"
      hide-footer
      centered
      size="lg"
      title="Update Schesule"
    >
      <form-update-schedule
        :clinic="clinic"
        :doctor="doctor"
        :clinic-options="clinicOptions"
        :doctor-options="doctorOptions"
        :doctor-code-id="doctorCodeID"
        :start-time-options="startTimeOptions"
        :end-time-options="endTimeOptions"
        :base-schedule="baseSchedule"
        @exit="onCloseModal"
      />
    </b-modal>
    <!-- Modal Dekete Schedule -->
    <b-modal
      id="formDeleteScheduleModal"
      hide-footer
      centered
      size="xl"
      title="Delete Schesule"
    >
      <form-delete-schedule
        :clinic="clinic"
        :doctor="doctor"
        :clinic-options="clinicOptions"
        :doctor-options="doctorOptions"
        :base-schedule="baseSchedule"
        @exit="onCloseModal"
      />
    </b-modal>
    <!-- Modal Create Appointment -->
    <b-modal
      id="formCreateAppointmentModal"
      hide-footer
      centered
      size="lg"
      title="Make Appointment"
    >
      <form-create-appointment
        :clinic="clinic"
        :doctor="doctor"
        :clinic-options="clinicOptions"
        :doctor-options="doctorOptions"
        :doctor-code-id="doctorCodeID"
        :base-schedule="baseSchedule"
        :date-appointment="dateAppointment"
        :day-appointment="dayAppointment"
        @exit="onCloseModal"
      />
    </b-modal>
    <!-- Modal Update Appointment -->
    <!-- <b-modal
      id="formUpdateAppointmentModal"
      hide-footer
      centered
      size="lg"
      title="Update Appointment"
    >
      <form-update-appointment
        :clinic="clinic"
        :doctor="doctor"
        :clinic-options="clinicOptions"
        :doctor-options="doctorOptions"
        :doctor-code-id="doctorCodeID"
        :base-schedule="baseSchedule"
        :date-appointment="dateAppointment"
        :day-appointment="dayAppointment"
        :appointment-id="appointmentID"
        :patient-id="patientID"
        @setupParameter="setupForUpdateAppointment"
        @exit="onCloseModal"
      />
    </b-modal> -->
    <!-- Modal Appointment Lists -->
    <b-modal
      id="formAppointmentListsModal"
      hide-footer
      centered
      size="xl"
      title="Appointment List"
    >
      <appointment-lists-in-schedule
        :clinic="clinic"
        :doctor="doctor"
        :clinic-options="clinicOptions"
        :doctor-options="doctorOptions"
        :doctor-code-id="doctorCodeID"
        :date-appointment="dateAppointment"
        @exit="onCloseModal"
      />
    </b-modal>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
// import timeGridPlugin from '@fullcalendar/timegrid'
// import listPlugin from '@fullcalendar/list'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// import ScheduleHeader from './ScheduleHeader.vue'
import FormCreateSchedule from './FormCreateSchedule.vue'
import FormUpdateSchedule from './FormUpdateSchedule.vue'
import FormDeleteSchedule from './FormDeleteSchedule.vue'
import FormCreateAppointment from '../appointments/FormCreateAppointment.vue'
// import FormUpdateAppointment from '../appointments/FormUpdateAppointment.vue'
import AppointmentListsInSchedule from '../appointments/AppointmentListsInSchedule.vue'

// const date = new Date()
// const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

export default {
  components: {
    FullCalendar,
    vSelect,
    // ScheduleHeader,
    FormCreateSchedule,
    FormUpdateSchedule,
    FormDeleteSchedule,
    FormCreateAppointment,
    // FormUpdateAppointment,
    AppointmentListsInSchedule,
  },
  directives: {
    Ripple,
  },
  watch: { },
  data() {
    return {
      scheduleModel: '',
      dayHeader: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      baseSchedule: [],
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getFullYear(),
      clinic: '',
      doctor: '',
      clinicOptions: [],
      doctorOptions: [],
      doctorCodeIDLists: [],
      value: '',
      drcode: '',
      doctorCodeID: '',
      fullScheduleInfo: [],
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          start: ' ',
          center: 'title',
          end: ' ',
        },
        events: [],
        eventColor: '#FFFFFF',
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        // eventRender: this.eventRender,
      },
      isCalendarOverlaySidebarActive: true,
      isEventHandlerSidebarActive: false,
      isScheduleHeaderActive: false,
      isScheduleMenuActive: false,
      scheduleList: [],
      schedulesColor: {
        Sun: 'color: red',
        Mon: 'color: #f7d794',
        Tue: 'color: #f78fb3',
        Wed: 'color: #9AECDB',
        Thu: 'color: #e77f67',
        Fri: 'color: #3dc1d3',
        Sat: 'color: #786fa6',
      },
      startTimeOptions: [],
      endTimeOptions: [],
      dateAppointment: '',
      dayAppointment: '',
      scheduleID: 0,
      appointmentID: 0,
      patientID: 0,
      scheduleSun: [],
      scheduleMon: [],
      scheduleTue: [],
      scheduleWed: [],
      scheduleThu: [],
      scheduleFri: [],
      scheduleSat: [],
    }
  },
  async created() {
    // this.dd = moment(new Date()).format('YYYY-MM')
    this.isScheduleMenuActive = false
    this.getClinic()
    if (this.$route.params.clinic && this.$route.params.clinic !== 'null') {
      this.clinic = this.$route.params.clinic
      await this.getDoctorByClinic()
      if (this.$route.params.doctor) {
        this.doctor = this.$route.params.doctor
      }
    }
    // Fix Time Options
    for (let t = 8; t < 20; t += 1) {
      const hourNumber = `0${t}`.substr(-2)
      this.startTimeOptions.push({ id: `${t}0`, code: `${hourNumber}:00`, label: `${hourNumber}:00` })
      this.startTimeOptions.push({ id: `${t}1`, code: `${hourNumber}:30`, label: `${hourNumber}:30` })
      this.endTimeOptions = this.startTimeOptions
    }
    // // console.log(this.startTimeOptions)
  },
  // mounted() {
  //   window.addEventListener('keydown', e => {
  //     // console.log(e)
  //   })
  // },
  methods: {
    checkCurentDate(checkDate) {
      const currentDate = new Date().getTime()
      const clickDate = new Date(checkDate).getTime()
      if (clickDate >= currentDate) {
        return true
      }
      return false
    },
    checkScheduleUseByDate(checkDate) {
      // console.log(checkDate)
      const dayCheck = new Date(checkDate).getDay()
      let scheduleCheck = []
      switch (dayCheck) {
        case 0: scheduleCheck = this.scheduleSun
          break
        case 1: scheduleCheck = this.scheduleMon
          break
        case 2: scheduleCheck = this.scheduleTue
          break
        case 3: scheduleCheck = this.scheduleWed
          break
        case 4: scheduleCheck = this.scheduleThu
          break
        case 5: scheduleCheck = this.scheduleFri
          break
        case 6: scheduleCheck = this.scheduleSat
          break
        default: scheduleCheck = []
      }
      if (scheduleCheck.length > 0) {
        for (let i = 0; i < scheduleCheck.length; i += 1) {
          const startUse = new Date(scheduleCheck[i].startUse).getTime()
          const endUse = new Date(scheduleCheck[i].endUse).getTime()
          const clickDate = new Date(checkDate).getTime()
          // // console.log(scheduleCheck[i].startUse, scheduleCheck[i].endUse)
          // console.log(clickDate <= startUse, (endUse >= clickDate || endUse === 0))
          if (clickDate <= startUse && (endUse >= clickDate || endUse === 0)) {
            return true
          }
        }
      }
      return true
    },
    changeRoute() {
      this.$router.push(`/nurse/schedule/${this.clinic}`).catch(() => {})
      this.patientLists = []
      this.getDoctorByClinic()
    },
    // CheckKey(e) {
    //   // console.log(e.keyCode)
    //   // if (e.keyCode === 13) {
    //   // }
    //   // return
    // },
    // handleSelect(arg) {
    //   // console.log(arg)
    // },
    handleDateClick(arg) {
      // // console.log(arg)
      if (this.checkCurentDate(arg.date) && this.checkScheduleUseByDate(arg.date)) {
        this.dayAppointment = this.dayHeader[new Date(arg.date).getDay()]
        this.dateAppointment = arg.dateStr
        this.$bvModal.show('formCreateAppointmentModal')
      }
    },
    handleEventClick(arg) {
      // // console.log(arg.event.startStr)
      this.dayAppointment = this.dayHeader[new Date(arg.event.startStr).getDay()]
      this.dateAppointment = arg.event.startStr
      this.$bvModal.show('formAppointmentListsModal')
    },
    // handleMonthChange(arg) {
    //   // console.log('handleMonthChange', arg)
    // },
    // eventTextColor(arg) {
    //   // console.log(arg)
    // },
    prev() {
      this.$refs.scheduleCalendar.getApi().prev()
      this.getScheduleByDoctor()
      // // console.log(this.$refs.scheduleCalendar.getApi().getDate())
    },
    next() {
      this.$refs.scheduleCalendar.getApi().next()
      this.getScheduleByDoctor()
      // // console.log(new Date(this.$refs.scheduleCalendar.getApi().getDate()).getFullYear())
    },
    changeView(view) {
      this.$refs.scheduleCalendar.fireMethod('changeView', view)
      // console.log('View')
    },
    getDoctorCodeIDByCode() {
      // // console.log(this.doctorCodeIDLists)
      for (let i = 0; i < this.doctorCodeIDLists.length; i += 1) {
        if (this.doctorCodeIDLists[i].code === this.doctor) {
          return this.doctorCodeIDLists[i].id
        }
      }
      return ''
    },
    getClinic() {
      this.isScheduleHeaderActive = false
      this.isScheduleMenuActive = false
      this.$http({
        // url: '/api/ClinicDoctor/getClinicFromReg',
        url: 'http://192.168.100.147/api/ClinicDoctor/getClinic',
        method: 'GET',
        data: {},
      }).then(({ data }) => {
        const result = []
        for (let i = 0; i < data.length; i += 1) {
          const clinicname = `${data[i].clinicCode} - ${data[i].clinicName}`
          result.push({ id: i + 1, code: data[i].clinicCode, name: clinicname })
        }
        this.clinicOptions = result
      }).catch(error => {
        console.log(error)
      })
    },
    getDoctorByClinic() {
      this.doctor = null
      this.isScheduleHeaderActive = false
      this.isScheduleMenuActive = false
      this.$http({
        // url: `/api/Doctor/All/${this.clinic}`,
        url: `http://192.168.100.147/api/Doctor/All/${this.clinic}`,
        method: 'GET',
        data: {},
      }).then(({ data }) => {
        const result = []
        const result2 = []
        for (let i = 0; i < data.length; i += 1) {
          const docname = `${data[i].docCode} - ${data[i].docName}`
          result.push({ id: i + 1, code: data[i].docCode, name: docname })
          result2.push({ id: data[i].doctorCodeID, code: data[i].docCode })
        }
        this.doctorOptions = result
        this.doctorCodeIDLists = result2
        this.getScheduleByDoctor()
        // // console.log(result2)
      }).catch(error => {
        console.log(error)
      })
    },
    getScheduleByDoctor() {
      // // console.log('Load Schedule')
      this.$router.push(`/nurse/schedule/${this.clinic}/${this.doctor}`).catch(() => {})
      this.currentMonth = new Date(this.$refs.scheduleCalendar.getApi().getDate()).getMonth() + 1
      this.currentYear = new Date(this.$refs.scheduleCalendar.getApi().getDate()).getFullYear()
      this.currentMonth = `0${this.currentMonth}`.substr(-2)
      this.doctorCodeID = this.getDoctorCodeIDByCode()
      if (this.doctorCodeID === '') {
        return
      }
      this.$http({
        url: '/api/DoctorSchedule/MonthlyDoctorSchedule',
        method: 'POST',
        data: {
          DoctorCodeID: this.doctorCodeID,
          Day: '',
          Month: this.currentMonth,
          Year: `${this.currentYear}`,
        },
      }).then(({ data }) => {
        this.fullScheduleInfo = data
        this.isScheduleMenuActive = true
        const scheduleList = []
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].maxPatient > 0) {
            const dataTemp = data[i]
            const sumPatientAppointment = `${dataTemp.sumPatientAppointment}`
            const sumPatientRegistered = `${dataTemp.sumPatientRegistered}`
            const currentPatirent = this.$helpers.IntNull(sumPatientRegistered) + this.$helpers.IntNull(sumPatientAppointment)
            const maxPatient = `${dataTemp.maxPatient}`
            scheduleList.push({
              id: i,
              url: '',
              title: `${currentPatirent} / ${maxPatient}`,
              start: dataTemp.date,
              end: dataTemp.date,
              allDay: true,
            })
          }
        }
        this.calendarOptions.events = scheduleList
        // // console.log(scheduleList)
        this.getBaseSchedule()
      }).catch(error => {
        console.log(error)
      })
    },
    getBaseSchedule() {
      this.baseSchedule = []
      this.scheduleSun = []
      this.scheduleMon = []
      this.scheduleTue = []
      this.scheduleWed = []
      this.scheduleThu = []
      this.scheduleFri = []
      this.scheduleSat = []
      for (let i = 0; i < this.dayHeader.length; i += 1) {
        this.$http({
          url: '/api/DoctorSchedule/BaseScheduleDetail',
          method: 'POST',
          data: {
            DoctorCodeID: this.doctorCodeID,
            Day: this.dayHeader[i],
          },
        }).then(({ data }) => {
          for (let s = 0; s < data.length; s += 1) {
            const dataSchedule = data[s]
            const currentDate = new Date().getTime()
            const startUse = new Date(dataSchedule.startUse).getTime()
            const endUse = new Date(dataSchedule.endUse).getTime()
            const currentMonth = new Date().getMonth()
            const startMonth = new Date(dataSchedule.startUse).getMonth()
            const scheduleDate = new Date(this.$refs.scheduleCalendar.getApi().getDate()).getTime()
            const scheduleMonth = new Date(this.$refs.scheduleCalendar.getApi().getDate()).getMonth()
            // // console.log(dataSchedule.startUse, dataSchedule.endUse, currentDate, startUse, endUse)
            // // console.log(dataSchedule.isActive, startUse <= currentDate, (startUse >= currentDate && startMonth === currentMonth), (startUse >= currentDate && startMonth === scheduleMonth), (endUse >= currentDate || endUse === 0))
            // if (startUse <= clickDate && (endUse >= clickDate || endUse === 0)) {
            if (dataSchedule.isActive
            && (startUse <= currentDate
            || (startUse >= scheduleDate && startMonth === currentMonth)
            || (startUse >= scheduleDate && startMonth === scheduleMonth))
            && (endUse >= currentDate || endUse === 0)) {
              const startSch = dataSchedule.startUse.substr(0, 10)
              let endSch = 'Present'
              if (dataSchedule.endUse !== null) {
                endSch = dataSchedule.endUse.substr(0, 10)
              }
              dataSchedule.useRange = `${startSch} - ${endSch}`
              switch (this.dayHeader[i]) {
                case 'Sunday': this.scheduleSun.push(dataSchedule)
                  break
                case 'Monday': this.scheduleMon.push(dataSchedule)
                  break
                case 'Tuesday': this.scheduleTue.push(dataSchedule)
                  break
                case 'Wednesday': this.scheduleWed.push(dataSchedule)
                  break
                case 'Thursday': this.scheduleThu.push(dataSchedule)
                  break
                case 'Friday': this.scheduleFri.push(dataSchedule)
                  break
                case 'Saturday': this.scheduleSat.push(dataSchedule)
                  break
                default: break
              }
            }
          }
          this.baseSchedule = [
            { id: 0, data: this.scheduleSun },
            { id: 1, data: this.scheduleMon },
            { id: 2, data: this.scheduleTue },
            { id: 3, data: this.scheduleWed },
            { id: 4, data: this.scheduleThu },
            { id: 5, data: this.scheduleFri },
            { id: 6, data: this.scheduleSat },
          ]
        }).catch(error => {
          console.log(error)
        })
      }
    },
    showCreateScheduleForm() {
      this.$bvModal.show('formCreateScheduleModal')
    },
    showUpdateScheduleForm() {
      this.$bvModal.show('formUpdateScheduleModal')
    },
    showDeleteScheduleForm() {
      this.$bvModal.show('formDeleteScheduleModal')
    },
    onCloseModal(id) {
      this.$bvModal.hide(id)
      this.getScheduleByDoctor()
    },
    setupForUpdateAppointment(appointmentID, patientID) {
      this.appointmentID = appointmentID
      this.patientID = patientID
      // console.log('Update', this.appointmentID, this.patientID)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import "@core/scss/vue/libs/vue-context.scss";

.fc-event-main {
  float: right;
  font-size: 1.2em;
}
</style>
